@font-face {
font-family: '__sofiapro_ef52d2';
src: url(https://taxes-f154wtlf6.purposity.app/_next/static/media/2f3ffd6913e347e8-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__sofiapro_Fallback_ef52d2';src: local("Arial");ascent-override: 67.81%;descent-override: 21.72%;line-gap-override: 5.41%;size-adjust: 105.45%
}.__className_ef52d2 {font-family: '__sofiapro_ef52d2', '__sofiapro_Fallback_ef52d2';font-weight: 700;font-style: normal
}.__variable_ef52d2 {--font-sofiapro: '__sofiapro_ef52d2', '__sofiapro_Fallback_ef52d2'
}

@font-face {
font-family: '__gilroy_f711c5';
src: url(https://taxes-f154wtlf6.purposity.app/_next/static/media/4f9d35a96b77d8f8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gilroy_f711c5';
src: url(https://taxes-f154wtlf6.purposity.app/_next/static/media/c2d4c9c2542b905c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gilroy_f711c5';
src: url(https://taxes-f154wtlf6.purposity.app/_next/static/media/9cda8d25de73986b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__gilroy_Fallback_f711c5';src: local("Arial");ascent-override: 88.14%;descent-override: 23.02%;line-gap-override: 0.00%;size-adjust: 104.36%
}.__className_f711c5 {font-family: '__gilroy_f711c5', '__gilroy_Fallback_f711c5'
}.__variable_f711c5 {--font-gilroy: '__gilroy_f711c5', '__gilroy_Fallback_f711c5'
}

@font-face {
font-family: '__sourcesanspro_794761';
src: url(https://taxes-f154wtlf6.purposity.app/_next/static/media/10357e89acf108be-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__sourcesanspro_794761';
src: url(https://taxes-f154wtlf6.purposity.app/_next/static/media/a736140e6931627e-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__sourcesanspro_794761';
src: url(https://taxes-f154wtlf6.purposity.app/_next/static/media/1fd6def6d58799d2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__sourcesanspro_Fallback_794761';src: local("Arial");ascent-override: 104.47%;descent-override: 28.98%;line-gap-override: 0.00%;size-adjust: 94.19%
}.__className_794761 {font-family: '__sourcesanspro_794761', '__sourcesanspro_Fallback_794761'
}.__variable_794761 {--font-sourcesanspro: '__sourcesanspro_794761', '__sourcesanspro_Fallback_794761'
}

